import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { OfferproductComponent } from '../offerproduct/offerproduct.component';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-addoffer',
  templateUrl: './addoffer.component.html',
  styleUrls: ['./addoffer.component.css']
})
export class AddofferComponent implements OnInit {


  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  isimport: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  filterby: String = "";
  pageColl: any = [];
  warehouseColl: any = [];

  offertypeColl: any = [];

  offerdetailsColl: any = [];

  checkofferdetailsColl: any = [];
  tempofferdetailsColl: any = [];

  //Edit Get Values
  offerColl: any = [];
  offerid: number = 0
  warehouseid: number = null
  statusid: number = null


  offerform = new FormGroup({
    offername: new FormControl('', Validators.required),
    offertype: new FormControl('', Validators.required),
    offerfrom: new FormControl(null),
    offerto: new FormControl(null),
    offerfromtime: new FormControl(null),
    offertotime: new FormControl(null),
    warehouseid: new FormControl(null),
    offerid: new FormControl(0),
    pageid: new FormControl(null),
    offercode: new FormControl(''),
    inactive: new FormControl(false),
  });
  dtOptions: { searching: boolean; pageLength: number; serverSide: boolean; processing: boolean; dom: string; columns: { title: string; }[]; order: (string | number)[]; scrollX: boolean; scrollCollapse: boolean; };

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Offer";

  _routerSub = Subscription.EMPTY;
  _routerparamsSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe, private loginService: LoginDataService) {
    this._routerSub = this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.offerid = params.id
      }

      if (params.filterby != undefined) {
        this.filterby = params.filterby

      }


      if (params.warehouseid !== undefined) {
        this.warehouseid = Number(params.warehouseid)
      }
      if (params.statusid !== undefined) {
        this.statusid = Number(params.statusid)
      }
    });


    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
    if (this._routerparamsSub !== undefined) {
      this._routerparamsSub.unsubscribe();
    }

    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.offerform.reset();
      this.fnInitializeOfferFormGroup();
      this.dtOptions = {
        searching: true,
        pageLength: 25,
        serverSide: false,
        processing: true,
        dom: '<"top"f>rt<"bottom"ilp><"clear">',
        columns: [
          { title: 'Action' },
          { title: 'Product Name' },
          { title: 'Sku' },
          { title: 'Regular Price' },
          { title: 'Offer Price' },


        ],
        order: [1, 'desc'],
        scrollX: true,
        scrollCollapse: true,

      };

      if (this.offerid > 0) {
        this.fngetOffer();
      }

      this.fngetWarehouse();
      this.fnGetOffertype();
      this.fngetPage();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.offerid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeOfferFormGroup() {
    this.offerform.setValue({
      offertype: '',
      offername: '',
      offerfrom: null,
      offerto: null,
      offerfromtime: null,
      offertotime: null,
      warehouseid: null,
      offerid: 0,
      pageid: null,
      offercode: '',
      inactive: false,
    })
  }



  fnGetOffertype() {
    this.offertypeColl = [];

    this.offertypeColl.push({ name: 'WEEKENDOFFER', displayname: 'Week End Offer' })
    this.offertypeColl.push({ name: 'FESTIVALOFFER', displayname: 'Festival Offer' })


  }


  fngetWarehouse() {
    var data = {
      'warehouseid': 0,
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          this.warehouseColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fngetPage() {
    var data = {
      'pageid': 0,
    }
    this.appService.getPageByListView(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            this.pageColl = res.json().result;
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  onFileChange(event: any) {


    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects


      this.fnInsertImport(data)
      console.log(data)
    
    };
  }


  //Insert
  fnInsertImport(detailsdata) {

    console.log(detailsdata)

    var ischeck: boolean = false;
    var errorfeild: any = "";

    // for (let abo in data) {

    // }

    if (detailsdata.length) {
      for (let abo of detailsdata) {
        if (abo.sku == undefined) {
          errorfeild = "SKU Column Incorrect"
          // alert(errorfeild)
          break
        }

        if (abo.itemname == undefined) {
          errorfeild = "Product Name Column Incorrect"
          break
        }

        if (abo.price == undefined) {
          errorfeild = "Offer Price Column Incorrect"
          break
        }


        ischeck = true


        break
      }
    }

    // if (!ischeck) {
    //   this.toastr.error('', "Excel "+errorfeild);
    //   return
    // }


    this.fnShowProgress();
    var data = {
      'doctype': "OFFER",
      'date': this.fnFormatDatetime(this.offerform.get('offerfrom').value),
      'warehouseid': this.offerform.get('warehouseid').value,
      'inactive': 0,
      'createdby': this.loginService.fnGetModUserId(),
      'modifiedby': this.loginService.fnGetModUserId(),
      'importdetailscoll': detailsdata
    }
    console.log(data)

    this.appService.fnInsertImport(data).subscribe(
      (res) => {
        console.log(res)
        this.fnHideProgress();

        console.log(res.json())
        if (res.json().status == 200) {
          this.toastr.success('', "The new Import has been added successfully.Insert Successfully");

          this.isimport = true;


          if (res.json().result.length > 2) {
            this.offerdetailsColl = res.json().result[2];
          }

          //this.pricechangedetailsColl = res.json().result[0];
          //  this.pricechangedetailsnotmatchColl = res.json().result[1];

        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }




  //Get 
  fngetOffer() {
    var data = {
      'offerid': this.offerid,
    }
    this.appService.getOfferbyOfferid(data)
      .subscribe(
        (res) => {

          this.offerColl = res.json().result[0];


          var editdata = {
            'offerid': this.offerColl[0].offerid,
            'offername': this.offerColl[0].offername,
            'offertype': this.offerColl[0].offertype,
            'offerfrom': this.offerColl[0].offerfrom,
            'offerto': this.offerColl[0].offerto,
            'offerfromtime': this.offerColl[0].offerfromtime,
            'offertotime': this.offerColl[0].offertotime,
            'warehouseid': this.offerColl[0].warehouseid,
            'pageid': this.offerColl[0].pageid,
            'offercode': this.offerColl[0].offercode,
            'inactive': this.offerColl[0].inactive,
          }
          this.fnEditMode(editdata);
          this.offerdetailsColl = res.json().result[1];


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.offerform.valid) {
        //Check
        var data = {
          'offerid': this.offerid,
          'offertype': this.offerform.get('offertype').value,
          'offername': this.offerform.get('offername').value,
          'offerfrom': this.fnFormatDatetime(this.offerform.get('offerfrom').value),
          'offerto': this.fnFormatDatetime(this.offerform.get('offerto').value),
          'offerfromtime': this.offerform.get('offerfromtime').value,
          'offertotime': this.offerform.get('offertotime').value,
          'warehouseid': this.offerform.get('warehouseid').value,
          'pageid': this.offerform.get('pageid').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'offerdetailscoll': this.offerdetailsColl
        }




        this.appService.fnCheckOfferProducts(data).subscribe(
          (res) => {
            this.checkofferdetailsColl = res.json().result;


            if (this.fnCheckOfferProducts() === false) {
              if (this.offerform.get('offerid').value > 0) {
                //Update Department
                this.fnSetOffer(data);
              }
              else {
                //Insert Department
                this.fnInsertOffer(data);
              }
            }
            else {
              const modalRef = this.productFindService.open(OfferproductComponent, { size: <any>'xl' });
              modalRef.componentInstance.productColl = this.tempofferdetailsColl;
            }


          },
          (err) => {
            console.log(err)
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertOffer(data) {
    this.fnShowProgress();
    this.appService.fnInsertOffer(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().status === 200) {
          this.toastr.success('', "The new offer has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.offername);

          this.fnCloseOffer();
        }
        else {
          this.fnHideProgress();
          console.log(res.json());
          this.toastr.error('Error', res.json());
        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetOffer(data) {
    console.log(data);
    this.fnShowProgress();
    this.appService.fnSetOffer(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().status === 200) {
          this.toastr.success('', "The offer has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.offerid, data.offername);

          this.fnCloseOffer();
        }
        else {
          this.fnHideProgress();
          console.log(res.json());
          this.toastr.error('Error', res.json());
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseOffer() {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.offerid,
          'warehouseid': this.warehouseid,
          'filterby': this.filterby,
        }

      }

      this.router.navigate(['/Offer'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Offer Product
  fnAddOfferProduct() {

    var warehouseid = this.offerform.get('warehouseid')!.value


    const modalRef = this.productFindService.open(ProductfindComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "RELATED PRODUCT";
    modalRef.componentInstance.offerwarehouseid = warehouseid;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {



      for (let wbo of receivedEntry) {

        if (this.offerdetailsColl.length === 0) {
          wbo.regularprice = wbo.sellingprice;

          this.offerdetailsColl.push(wbo);
        }
        else {

          const exitindex = this.offerdetailsColl.findIndex(sku => sku.productid === wbo.productid && sku.skuid === wbo.skuid);

          if (exitindex < 0) {
            wbo.regularprice = wbo.sellingprice;

            this.offerdetailsColl.push(wbo);
          }

        }


      }
    });


  }

  fnDeleteOfferProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Related Product')
      .then((confirmed) => {
        if (confirmed) {

          this.offerdetailsColl.splice(prodindex, 1);

        }
      });
  }

  fnCheckOfferProducts() {
    var _ischeck: boolean = false;

    this.tempofferdetailsColl = [];

    for (let abo of this.checkofferdetailsColl) {
      for (let pbo of this.offerdetailsColl) {
        if (abo.skuid === pbo.skuid && abo.offerdetailsid !== pbo.offerdetailsid) {
          this.tempofferdetailsColl.push(abo);
        }
      }
    }

    if (this.tempofferdetailsColl.length > 0) {
      _ischeck = true;
    }
    return _ischeck;

  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  private fnEditMode(deptBO) {

    deptBO.offerfrom = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.offerfrom));
    deptBO.offerto = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.offerto));

    this.offerform.setValue({
      offertype: deptBO.offertype,
      offername: deptBO.offername,
      offerfrom: deptBO.offerfrom,
      offerto: deptBO.offerto,
      offerfromtime: deptBO.offerfromtime,
      offertotime: deptBO.offertotime,
      warehouseid: deptBO.warehouseid,
      offerid: deptBO.offerid,
      pageid: deptBO.pageid,
      offercode: deptBO.offercode,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }




  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
